import React, { useEffect, useState } from "react"

import Layout from "../components/Layout"
import CostTicker from "../components/CostTicker"

import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"

import { FacebookShareButton, TwitterShareButton } from "react-share"

import { FacebookIcon, TwitterIcon } from "react-share"

export default () => {
  const bgData = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "EU-flag-red2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  )
  const [thisUrl, setThisUrl] = useState("")
  useEffect(() => {
    setThisUrl(window.location.href)
  })
  console.log(thisUrl)

  const year = new Date().getFullYear()

  const fees = {
    2020: 41989649000,
    2021: 45037439000,
    2022: 45114000000,
    2023: 45869852000,
    2024: 40744809000,
    2025: 47761911000,
    2026: 54794000000,
    2027: 58173000000
  }

  return (
    <Layout>
      <BackgroundImage
        Tag="section"
        fluid={bgData.desktop.childImageSharp.fluid}
        title="Fullscreen Background"
        id="fullscreenbg"
        role="img"
        aria-label="Fullscreen Background"
        preserveStackingContext={true}
      >
        <div
          className="hero-banner d-flex flex-column"
          style={{ height: "0px" }}
        >
          <div className="h-65  w-100 mt-auto d-flex flex-column text-white justify-content-center">
            <div className="container text-center">
              <p className="display-3">
                <strong>
                  <CostTicker incrementInterval={50} totalCost={fees[year]} />
                </strong>
              </p>
              <h5 className="cost-byline">Sveriges EU-avgift {year}</h5>
              <h6 className="pt-5">Upplys dina vänner, dela det här:</h6>
              <span className="px-2">
                <FacebookShareButton url={thisUrl} hashtag={"#swexit"}>
                  <FacebookIcon round={true} size={35} />
                </FacebookShareButton>
              </span>
              <span className="px-2">
                <TwitterShareButton
                  url={thisUrl}
                  title={"Så mycket har EU kostat i år!"}
                  hashtags={["swexit"]}
                >
                  <TwitterIcon round={true} size={35} />
                </TwitterShareButton>
              </span>
            </div>
            <div className="container pb-4 mt-auto">
              <div className="col-md-6 ml-auto text-right">
                <small className="text-white ">
                  Sveriges avgift till Europeiska unionen uppgår till{" "}
                  <span className="text-nowrap">
                    {fees[year].toLocaleString("sv-SE", { currency: "SEK" })}
                  </span>{" "}
                  kronor för {year}. <br />
                  Källa: <a href="https://regeringen.se/contentassets/bfe4593f9b0d462f834bc8bbd052a921/budgetpropositionen-for-2025-hela-dokumentet-prop.2024251.pdf">Regeringens budget för 2025.</a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>

      <div className="container py-5">
        <div className="row text-center">
          <div className="text-center py-3 mx-auto">
            <h1 className="text-center">
              För EU-avgiften skulle vi istället kunna:
            </h1>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-lg-3 text-center pt-2">
            <h3>Införa fri tandvård</h3>
            <p>
            Att införa fri tandvård för hela befolkningen, på samma villkor som övrig sjukvård, beräknas kosta 20 miljarder kronor.
            </p>
          </div>
          <div className="h1 flex-grow-1 text-center align-self-center">+</div>
          <div className="col-lg-3 text-center pt-2">
            <h3>Höja lönen för undersköterskor</h3>
            <p>
            Att höja lönen med 5 800 kronor i månaden för samtliga undersköterskor i Sverige beräknas kosta 10 miljarder. 
            </p>
          </div>
          <div className="h1 flex-grow-1 text-center align-self-center">+</div>
          <div className="col-lg-3 text-center pt-2">
            <h3>Slopa straffskatterna på bensin</h3>
            <p>
            Att helt ta bort koldioxid- och energiskatterna på bensin beräknas kosta 17 miljarder kronor.
            </p>
          </div>
        </div>
 
      </div>
    </Layout>
  )
}
